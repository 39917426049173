@font-face {
  font-family: "poppins";
  src: url("asset/fonts/poppins/Poppins-Regular.ttf");
  
}

@font-face {
  font-family: "leaque-spartan";
  src: url("asset/fonts/leaque spartan/LeagueSpartan-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: "leaque-spartan" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #f4f6f9;
}

.loader-container{
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: center;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.small-box{
  border-radius: 20px;
}
.small-box-footer{
  border-radius: 1px 1px 20px 20px;
}

.card{
  border-radius: 25px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: "transparent";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* wave */

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:250px;
}

.content {
  position:relative;
  min-height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 45s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 4s;
}
.parallax > use:nth-child(2) {
  animation-delay: -2s;
  animation-duration: 6s;
}
.parallax > use:nth-child(3) {
  animation-delay: -3s;
  animation-duration: 8s;
}
.parallax > use:nth-child(4) {
  animation-delay: -4s;
  animation-duration: 12s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:15vh;
  }
  h1 {
    font-size:24px;
  }
}

.brand{
  height: 50px;
  width : fit-content;
  padding: 5px;
  overflow: visible;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px 40px 40px 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
}

.brand .logo-container{
  border-radius: 10px 40px 40px 40px;
  height: 60px;
  width: 60px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand .logo-container img{
  height: 36px;
  width: 36px;
  margin-top: 5px;
}

.brand .brand-name{
  font-size:medium;
  line-height: 0;
  color: aliceblue;
  padding-left: 8px;
}

.login-card-body p {
  color: azure;
} 
.nav-item .active{
  border-radius: 70px;
  background-color: rgba(187, 200, 213, 0.5);
}

.nav-item{
  border-radius: 70px;
}

.nav-link:hover{
  background-color: rgba(85, 85, 85, 0.182);
  border-radius: 70px;
}
